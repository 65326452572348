var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade" } },
    [
      _vm.api.isError
        ? _c(
            "v-alert",
            { attrs: { dense: "", border: "left", type: "warning" } },
            [_vm._v(" " + _vm._s(_vm.api.error) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }