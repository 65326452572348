var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "700" },
      on: {
        "click:outside": function ($event) {
          return _vm.$emit("cancel")
        },
      },
      model: {
        value: _vm.isShow,
        callback: function ($$v) {
          _vm.isShow = $$v
        },
        expression: "isShow",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-3" },
        [
          _c("v-card-text", { staticClass: "py-4" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "py-3",
                    attrs: { size: "150", color: "warning" },
                  },
                  [_vm._v(" fa-exclamation-circle ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex mt-3 justify-center align-center font-weight-bold text-h4 ",
              },
              [_vm._v(" Are you confirm to proceed ? ")]
            ),
            _c(
              "div",
              {
                staticClass: "d-flex mt-3 justify-center align-center text-h6 ",
              },
              [_vm._v(" You won't be able to revert the changes ")]
            ),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", size: "100", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("cancel")
                          },
                        },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("confirm")
                          },
                        },
                      },
                      [_vm._v(" Confirm ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }