var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { align: "start", justify: "start" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.redirectBack },
                },
                [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }