var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      this.text != null
        ? _c("p", { staticClass: "copy-cursor", on: { click: _vm.copyText } }, [
            _vm._v(" " + _vm._s(this.text) + " "),
          ])
        : _c("p", [_vm._v(" - ")]),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: _vm.timeout,
            color: "success",
            top: "",
            center: "",
          },
          model: {
            value: _vm.showPopup,
            callback: function ($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup",
          },
        },
        [_vm._v(" " + _vm._s(this.message) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }