import axios from 'axios';

export default{
    state:{
        features:{
            subscriptionHistory:null,
            onboarding:null,
            noinvoice:null,
            refund:null,
            attendancelist:null,
            accounttype:null,
            invoice:null,
            sales:null,
            hrdc:null,
            aae:null,
            survey:null,
            funnel:null,
            pageCompaniesDetailv2: null,
            dropdown:null,
            analysis:null,
            specialremark:null,
            staffList:null,
        }
    },
    getters: {
        getFeatures(state) {
            return state.features;
        },
    },
    actions: {
        fetchFeatures(context) {
            axios.get(process.env.VUE_APP_SERVER_API+"/developer/features").then(response=>{
                context.commit('updateFeatures',response.data.data);
            })
        }
    },
    mutations: {
        updateFeatures(state,features) {
            features.forEach((item)=>{
                if(item.features==="subscription_history")
                {
                    state.features.subscriptionHistory = item;
                }
                if(item.features==="onboarding")
                {
                    state.features.onboarding = item;
                }
                if(item.features==='no_invoice') {
                    state.features.noinvoice = item;
                }
                if(item.features==="refund")
                {
                    state.features.refund = item;
                }
                if(item.features==='attendance_list')
                {
                    state.features.attendancelist = item;
                }
                if(item.features==="sales")
                {
                    state.features.sales = item;
                }
                if(item.features==="hrdc")
                {
                    state.features.hrdc = item;
                }
                if(item.features==="aae")
                {
                    state.features.aae = item;
                }
                if(item.features==='account_type')
                {
                    state.features.accounttype = item;
                }
                if(item.features==="funnel")
                {
                    state.features.funnel = item;
                }
                if(item.features==='account_type')
                {
                    state.features.accounttype = item;
                }
                if(item.features==='invoice') 
                {
                    state.features.invoice = item;
                }
                if(item.features==='pageCompaniesDetailv2') 
                {
                    state.features.pageCompaniesDetailv2 = item;
                }
                if(item.features==='survey') 
                {
                    state.features.survey = item;
                }
                if(item.features==='dropdown') {
                    state.features.dropdown = item;
                }
                if(item.features==='analysis') {
                    state.features.analysis = item;
                }
                if(item.features==='special_remark') {
                    state.features.specialremark = item;
                }
                if(item.features==='staff_list') {
                    state.features.staffList = item;
                }
            })
            return state.features;
        }
    }

}