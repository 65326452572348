var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.isDisplayTask
        ? _c("ComponentDetailActiveTask", {
            on: {
              close: _vm.closeActiveTask,
              "click:outside": _vm.closeActiveTask,
            },
          })
        : _vm._e(),
      _c(
        "v-btn",
        {
          staticStyle: {
            position: "fixed",
            bottom: "20px",
            left: "94%",
            "z-index": "9990",
          },
          attrs: { rounded: "", color: "primary" },
          on: {
            click: function () {
              this$1.isDisplayTask = true
            },
          },
        },
        [_c("v-icon", [_vm._v(" bi-clipboard-check ")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }