var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { height: "700", width: "700" },
      on: {
        "click:outside": function ($event) {
          return _vm.$emit("close")
        },
      },
      model: {
        value: _vm.isShow,
        callback: function ($$v) {
          _vm.isShow = $$v
        },
        expression: "isShow",
      },
    },
    [
      _c("v-card", { staticClass: "pa-3" }, [
        _c("div", { staticClass: "d-flex justify-center" }, [
          _c("div", { staticClass: "wrapper" }, [
            _c(
              "svg",
              {
                staticClass: "checkmark",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 52 52",
                },
              },
              [
                _c("circle", {
                  staticClass: "checkmark__circle",
                  attrs: { cx: "26", cy: "26", r: "25", fill: "none" },
                }),
                _vm._v(" "),
                _c("path", {
                  staticClass: "checkmark__check",
                  attrs: { fill: "none", d: "M14.1 27.2l7.1 7.2 16.7-16.8" },
                }),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "d-flex justify-center  mt-5" }, [
          _c("div", { staticClass: "flex-column" }, [
            _c("div", { staticClass: "d-flex justify-center" }, [
              _c(
                "p",
                {
                  staticClass: "green--text ",
                  staticStyle: {
                    "font-size": "2.125rem",
                    "letter-spacing": "10px",
                    "word-wrap": "break-word",
                  },
                },
                [_vm._v(" Awesome ")]
              ),
            ]),
            _c("div", { staticClass: "d-flex justify-center" }, [
              _c(
                "small",
                {
                  staticClass: "green--text",
                  staticStyle: { "font-size": "16px" },
                },
                [
                  _vm._v(
                    " Wow. You're doing fantastic. Don't forget to take a moment to relax. "
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "d-flex justify-center pt-5 mt-5" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary ", dense: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [_vm._v(" Continue ")]
                ),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "d-flex justify-center mt-2" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }