var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.checkFormat()
    ? _c("span", [_vm._v(" " + _vm._s(this.formatDate()) + " ")])
    : _c("span", [_vm._v(" - ")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }