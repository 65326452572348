var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.api
      ? _c(
          "div",
          [
            _c("v-alert", { attrs: { elevation: "6", type: "success" } }, [
              _vm._v(" " + _vm._s(_vm.message) + " "),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }