var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { height: "700", width: "700" },
      on: {
        "click:outside": function ($event) {
          return _vm.$emit("close")
        },
      },
      model: {
        value: _vm.notAllowed,
        callback: function ($$v) {
          _vm.notAllowed = $$v
        },
        expression: "notAllowed",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-3" },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-center" },
            [
              _c("v-img", {
                attrs: {
                  src: "/images/robot.svg",
                  contain: "",
                  "max-width": "150px",
                  "max-height": "150px",
                },
              }),
            ],
            1
          ),
          _c("v-card-text", { staticClass: "py-4" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-center font-weight-bold text-h4" },
              [_vm._v(" Denied ")]
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-center align-center text-center my-2 text-h6",
              },
              [
                _vm._v(
                  " Sorry, but you are not allowed to access this section. "
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [_vm._v(" Okay ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }