var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.api.isLoading
      ? _c(
          "div",
          { staticClass: "text-center ma-5" },
          [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", color: "primary" },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }