var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "700" },
      on: {
        click: function ($event) {
          return _vm.$emit("close")
        },
        "click:outside": function ($event) {
          return _vm.$emit("close")
        },
      },
      model: {
        value: _vm.isDisplay,
        callback: function ($$v) {
          _vm.isDisplay = $$v
        },
        expression: "isDisplay",
      },
    },
    [
      _vm.isTaskCompleted
        ? _c("ATaskComplete", { on: { close: _vm.reloadPage } })
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "pa-3" },
        [
          _c("div", { staticClass: "d-flex justify-center" }, [
            _c("p", { staticClass: "text-h5 font-weight-bold ma-4 pa-4" }, [
              _vm._v(" My Pick-Up Task "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              !_vm.api.isLoading && _vm.dataTable != null
                ? _c(
                    "v-btn",
                    {
                      staticClass: "my-4",
                      attrs: {
                        disabled: !_vm.isTaskSelected,
                        color: "primary",
                      },
                      on: { click: _vm.updateMultipleTaskStatus },
                    },
                    [_vm._v(" Mark As Complete ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.api.isLoading
            ? _c("v-skeleton-loader", {
                ref: "skeleton",
                attrs: { type: "table" },
              })
            : _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  this.dataTable != null
                    ? _c("v-data-table", {
                        attrs: {
                          items: this.dataTable,
                          headers: _vm.tableHeaders,
                          dense: "",
                          outlined: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.company_name",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "PageCompaniesDetail",
                                          params: { id: item.company_id },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.company_name) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.task_action",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(item.task_action),
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "item.created_at",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.convertTimeZone(item.created_at)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.task_status",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c("v-checkbox", {
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateCheckbox()
                                      },
                                    },
                                    model: {
                                      value: item.checkbox,
                                      callback: function ($$v) {
                                        _vm.$set(item, "checkbox", $$v)
                                      },
                                      expression: "item.checkbox",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1475055172
                        ),
                      })
                    : _c("div", { staticClass: "mt-4 text-h6" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center flex-row" },
                          [_c("span", [_vm._v(" No Active Task Recorded ")])]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "d-flex justify-center flex-row my-4",
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary" },
                                on: { click: _vm.redirectMyTask },
                              },
                              [_vm._v(" Go To My Task ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }