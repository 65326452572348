var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticStyle: { "background-color": "#F2F3F7" } },
    [
      _c("ComponentActiveTask"),
      _c("Sidebar"),
      _c(
        "v-app-bar",
        {
          staticStyle: {
            "background-color": "#F2F3F7",
            "margin-left": "0px",
            "margin-right": "0px",
          },
          attrs: { app: "", flat: "", "hide-overlay": "" },
        },
        [
          _c("v-toolbar-title", { staticClass: "font-weight-bold" }),
          _c("v-spacer"),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "px-0",
                  attrs: {
                    elevation: "0",
                    to: {
                      name: "PageTaskListUser",
                      params: { id: this.$store.getters.getUserId },
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(" bi-clipboard-check "),
                  ]),
                  _c("v-chip", { attrs: { color: "primary white--text" } }, [
                    _c("span", [
                      _vm._v(" " + _vm._s(this.notification.totalTask) + " "),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-right": "10px" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "px-3",
                  staticStyle: { "background-color": "#F2F3F7" },
                  attrs: { elevation: "0" },
                  on: { click: _vm.redirectNotification },
                },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(" mdi-bell-outline "),
                  ]),
                  _c("v-chip", { attrs: { color: "primary white--text" } }, [
                    _vm._v(
                      " " + _vm._s(this.notification.totalNotification) + " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  staticClass: "px-4",
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "pa-0 mx-3",
                                  attrs: {
                                    text: "",
                                    elevation: "0",
                                    color: "primary",
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "font-weight-bold",
                                  staticStyle: { "{font-size": "12px}" },
                                },
                                [_vm._v(" My Profile ")]
                              ),
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v(" bi-chevron-down "),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    {},
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-5",
                          attrs: { height: "auto", width: "auto" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center" },
                            [
                              _c(
                                "v-avatar",
                                { staticStyle: { background: "#E3E3E3" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-bold primary--text",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            this.$store.getters.getFname
                                              .toString()
                                              .charAt(0)
                                              .toUpperCase()
                                          ) +
                                          " " +
                                          _vm._s(
                                            this.$store.getters.getLname
                                              .toString()
                                              .charAt(0)
                                              .toUpperCase()
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center mt-4" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "font-weight-bold",
                                  staticStyle: { "font-size": "20px" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(this.$store.getters.getFname) +
                                      " " +
                                      _vm._s(this.$store.getters.getLname) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center mb-5" },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "16px",
                                    color: "#767374",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(this.$store.getters.getEmail) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-space-between mt-5",
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "black--text mx-4",
                                  attrs: { outlined: "" },
                                  on: { click: _vm.redirectStaffDetail },
                                },
                                [_vm._v(" Profile ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "black--text mx-4",
                                  attrs: { outlined: "" },
                                  on: { click: _vm.redirectLogout },
                                },
                                [_vm._v(" Logout ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        {
          staticStyle: {
            "background-color": "#F2F3F7",
            padding: "40px 0px 0px 256px",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "padding-left": "2%", "padding-right": "2%" } },
            [
              _c("router-view", {
                key: this.$router.history.current.path,
                staticStyle: { "background-color": "#F2F3F7" },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }